@import '../../../styles/variables';

.section-roadmap {
  padding: 8rem 0;
  position: relative;
  z-index: 8;

  .title-content-medium {
    padding-top: 5.312rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .header-content {
    font-family: 'VCR OSD Mono';
    text-transform: uppercase;
    color: black;
    margin-bottom: 2rem;
  }

  .description-content {
    color: black;
  }

  .container {
    height: 100%;
    max-width: 1980px;

    .title-content {
      position: relative;
      z-index: 4;
      text-align: center;
      img {
        width: 55%;
        margin-bottom: 2rem;
      }

      .dotted-box {
        margin-top: 5rem;
      }
    }

    .dotted-box {
      font-family: 'VCR OSD Mono';
      text-transform: uppercase;
      color: black;
      border: 3px dashed black;
      background-color: $yellow-orange;

      display: inline-block;

      padding: 0.5rem 2.5rem;
    }

    /* path variables */
    .path {
      z-index: 3;
      position: absolute;

      &.path-1 {
        width: 15%;
        margin-left: 41%;
      }

      &.path-2 {
        width: 12%;

        margin-left: 28%;
        margin-top: 34%;
      }

      &.path-3 {
        width: 17%;
        margin-left: 40.5%;
        margin-top: 46%;
      }

      &.path-4 {
        width: 18%;
        margin-left: 56.5%;
        margin-top: 49.5%;
      }

      &.path-5 {
        width: 20%;
        margin-left: 54.5%;
        margin-top: 32%;
      }
    }

    /* roadmap box variables */
    .roadmap-box {
      display: inline-block;
      text-align: center;

      position: absolute;
      min-width: 14rem;
      z-index: 4;

      font-size: 1rem;

      &.active {
        cursor: pointer;
      }

      &.box-1 {
        top: 38%;
        left: 26%;
      }

      &.box-2 {
        top: 63%;
        left: 31%;
      }

      &.box-3 {
        top: 66%;
        left: 52%;
        white-space: nowrap;
      }

      &.box-4 {
        top: 58%;
        left: 72%;
        white-space: nowrap;
      }

      &.box-5 {
        top: 37%;
        left: 66%;
        white-space: nowrap;
      }
    }

    // map images
    .map-image {
      z-index: 5;
      position: absolute;
      img {
        width: 50%;
      }

      &.art {
        margin-top: 37%;
        margin-left: 32.5%;
      }

      &.balaclava {
        margin-top: 58%;
        margin-left: 43.5%;
      }

      &.bin {
        margin-top: 59%;
        margin-left: 60%;
      }

      &.rally {
        margin-top: 46%;
        margin-left: 65.5%;
      }

      &.funding {
        margin-top: 33.5%;
        margin-left: 71%;
      }
    }

    .small-content-box {
      display: none;
      position: relative;
    }

    .pin-container {
      position: relative;
    }

    .roadmap {
      padding-bottom: 6.25rem;

      display: flex;
      flex-flow: column;

      position: relative;

      .roadmap-goal-modal {
        position: absolute;
        padding: 0;
        width: 20rem;

        top: 20%;
        left: -10%;

        text-align: center;

        .goal-title {
          color: black;
          border-bottom: 3px dashed black;
          font-family: 'VCR OSD Mono';
          text-transform: uppercase;

          display: flex;
          justify-content: center;
          margin: 0 auto;

          padding: 1rem 2rem;
        }
        .goal-description {
          color: black;
          text-transform: initial;

          padding: 2rem;
        }
      }

      .roadmap-image {
        margin-top: 9%;
        display: flex;
        justify-content: center;
        position: relative;
        img {
          margin: 0 auto;
          max-width: 110%;
          width: 90%;
        }
      }
      .roadmap-subtext {
        text-align: center;
        font-family: 'VCR OSD Mono';
        text-transform: uppercase;
        margin-top: 1rem;
      }
    }
  }

  @media screen and (max-width: 1800px) {
    .container {
      .dotted-box {
        padding: 0.3rem 1.5rem;
      }
      .roadmap-box {
        min-width: 14rem;
        &.box-3 {
          top: 65%;
        }
      }

      .map-image {
        img {
          width: 40%;
        }
      }

      .roadmap {
        .roadmap-goal-modal {
          // top: 25%;
          // left: -12%;
          width: 18rem;
          // .goal-title {
          //   padding: 0.75rem 1.5rem;
          // }
          // .goal-description {
          //   padding: 1.5rem;
          // }
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .pin-container {
      display: relative;
    }
    .container {
      .dotted-box {
      }
    }
  }

  @media screen and (max-width: 1450px) {
    padding: 6rem 0;

    .container {
      .dotted-box {
        padding: 0.25rem 1rem;
        font-size: 0.85rem;
        border: 2.4px dashed black;
      }

      .roadmap-box {
        min-width: 10rem;
        white-space: nowrap;

        &.box-2 {
          top: 63%;
        }
        &.box-3 {
          top: 64%;
        }
        &.box-4 {
          top: 56%;
        }
      }

      .map-image {
        img {
          width: 35%;
        }
        // &.art {
        //   margin-top: 45%;
        // }
        // &.balaclava {
        //   margin-top: 71%;
        //   margin-left: 42%;
        // }
        // &.funding {
        //   margin-top: 40.5%;
        // }
      }

      .roadmap {
        .roadmap-goal-modal {
          top: 16%;
          width: 16rem;

          .goal-title {
            padding: 0.75rem 1.5rem;
            font-size: 0.85rem;
            border-bottom: 2.4px dashed black;
          }

          .goal-description {
            padding: 1.5rem;
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    // .pin-container {
    //   display: none;
    // }

    .description-content,
    .header-content {
      font-size: 0.85rem;
    }

    .container {
      width: 100%;

      .title-content {
        img {
          width: 38%;
        }

        .dotted-box {
          // margin-top: 20rem;
        }
      }

      .roadmap-box {
        padding: 0.2rem 1rem;
        &.box-1 {
          left: 27.5%;
        }
        &.box-3 {
          top: 64.8%;
          padding: 0.4rem 0.8rem;
        }
        &.box-4 {
          top: 56.8%;
          padding: 0.325rem 0.6rem;
        }
      }

      .roadmap {
        .roadmap-goal-modal {
          top: 16%;
          left: 0%;
          margin-top: 0rem;
          width: 13rem;
        }
      }

      .small-artwork {
        position: absolute;
        z-index: 5;

        width: 5.5rem;
        background-color: $yellow-orange;
        padding: 1rem;

        border: 2.4px dashed black;

        &.art-small {
          left: 30.8%;
          margin-top: 35%;
          right: 0;
        }

        &.balaclava-small {
          left: 34%;
          bottom: 0;
          margin-bottom: 15rem;
        }

        &.bin-small {
          right: 38%;
          bottom: 0;
          margin-bottom: 16rem;
        }

        &.rally-small {
          right: 18%;
          bottom: 0;
          margin-bottom: 24rem;
        }

        &.funding-small {
          margin-top: 32%;
          right: 25%;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .container {
      .roadmap-box {
        font-size: 0.8rem;
        padding: 0.1rem 0.8rem;

        &.box-1 {
          top: 36%;
        }
        &.box-2 {
          top: 59%;
          left: 28%;
        }
        &.box-3 {
          top: 62.6%;
          left: 52%;
          padding: 0.2rem 0.8rem;
        }
        &.box-4 {
          top: 54.5%;
        }
        &.box-5 {
          top: 35%;
        }
      }

      .roadmap {
        .roadmap-goal-modal {
          top: 16%;
          width: 11rem;
          // top: auto;
          // bottom: 7%;
          // left: 14%;
        }
      }

      .map-image {
        img {
          width: 30%;
        }
      }
    }
  }

  @media screen and (max-width: 864px) {
    // padding: 4rem 0;
    display: none;
    // .container {
    //   .roadmap {
    //     .roadmap-goal-modal {
    //       // bottom: 3%;
    //       // left: 17%;
    //       // width: 17rem;
    //     }
    //   }
    // }
  }
  // @media screen and (max-width: 769px) {
  //   display: none;
  // }
}
