@import "../../styles/variables";

.loading-background {
    position: absolute;
    z-index: 200;

    background-color: white;
    height: 100vh;
    width: 100vw;
    opacity: 1;
    transition: 0.8s ease-in-out;

    overflow: hidden;

    &.hidden {
        opacity: 0;
        height: 0;
    }

    .container {
        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: center;
        flex-flow: column;
    }

    .loading-bin {
        margin: 0 auto;
        width: 18rem;
        transform: translateX(-9%);
    }

    .loading-text, .dots {
        font-size: 1.5rem;
        font-family: "VCR OSD Mono";
        font-weight: bold;
        color: black;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
    }

    .loading-text {
        padding-top: 2rem;
    }



    @media screen and (max-width: 380px) {
        .loading-bin {
            transform: translateX(-13%);
        }
    }
}
  