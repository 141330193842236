// typography
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-small {
  font-size: 0.8rem;
}

.font-bold {
  font-weight: 700;
}
//text-color

.text-transparent {
  width: 0;
  height: 0;
  color: transparent;
  opacity: 0;
}

// white-space
.no-wrap {
  white-space: nowrap;
}

// overflow
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-hidden {
  overflow: hidden;
}

// position
.position-absolute {
  position: absolute;
}

// background
.bg-red {
  background: $red;
}

.bg-orange-red {
  background: $orange-red;
}

.bg-blue {
  background: $blue;
}

.bg-black {
  background: $black;
}

.bg-black-grainy {
  background: $black;
}

.bg-light-black {
  background: $light-black;
}

.bg-yellow {
  background: $yellow;
}

.bg-yellow-orange {
  background: $yellow-orange;
}

.bg-orange {
  background: $orange;
}

.bg-green {
  background: $green;
}

// container
.container {
  width: 80%;
  max-width: 1920px;
  padding: 0 0.937rem;
  margin: 0 auto;

  @media screen and (max-width: 680px) {
    width: 85%;
  }

  @media screen and (min-width: 1980px) {
    max-width: none;
  }
}

// class
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.title-page-section {
  font-size: 1.5rem;
  color: $white;
}

.title-content-medium {
  font-family: "GTPressura Bold";
  font-size: 3.5rem;
  white-space: pre-line;
  line-height: 90%;
}

iframe.youtube {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.message-info {
  width: 300px;
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 0.937rem;
  text-align: center;
  word-break: break-word;

  p {
    font-size: 0.937rem;
    font-weight: 700;
  }

  a {
    display: block;
    text-decoration: underline;
    font-size: 0.875rem;
    margin-top: 5px;
    font-weight: 700;
  }

  @media screen and (max-width: 680px) {
    margin-top: 4.375rem;

    a {
      max-width: 240px;
      margin: 0 auto;
      margin-top: 0.625rem;
    }
  }
}

// display
.hidden-sm {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.hidden-md {
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.full-page {
  width: 100%;
  min-height: 100vh;
  margin-top: -1px;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.youtube-box {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// flex
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}
