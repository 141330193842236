
// GT Pressura
@font-face {
  font-family: "GTPressura";
  src: url("../fonts/GTPressura.ttf") format("truetype");
}

@font-face {
  font-family: "GTPressura Bold";
  src: url("../fonts/GTPressura-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "GTPressura Light";
  src: url("../fonts/GTPressura-Light.ttf") format("truetype");
}

// VCR
@font-face {
  font-family: "VCR OSD Mono";
  src: url("../fonts/VCR-OSD-Mono.ttf") format("truetype");
}



* {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-family: "GTPressura", Arial, sans-serif;
  font-weight: 300;
  color: $white;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

a {
  text-decoration: none;
}

button,
.btn-link {
  background: $blue;
  font-size: 1.125rem;
  color: $white;
  font-weight: 700;
  padding: 0.75rem 0.875rem 0.5rem 0.875rem;
  border: none;
  border-radius: 6px;
  text-transform: capitalize;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
}

ul,
li {
  list-style: none;
}

.title-arrow-intro {
  padding: 1.875rem 1.4rem 1.187rem 1.4rem;
  margin: 0 auto;
  max-width: 1920px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  .arrow {
    margin-right: 1.562rem;
    cursor: pointer;
  }
}
