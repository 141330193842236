@import "../../../styles/variables";

.section-video {
  position: relative;
  z-index: 8;

  .content {
    display: flex;
    align-content: center;
    flex-direction: column;
    width: 100%;

    .title-content-medium {
      text-align: center;
      margin-bottom: 2.8rem;
    }

    .youtube {
      display: flex;
      padding: 9rem 0;
      align-self: center;

      .revolt-video {
        position: relative;
        z-index: 10;
      }

      iframe,
      object,
      embed,
      .video-placeholder {
        width: 800px;
        height: 500px;
        min-width: 600px;
        min-height: 400px;
        img {
          cursor: pointer;
        }
      }

      .video-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .video-placeholder {
          display: flex;
          background-color: black;
          img {
            margin: auto;
          }
        }

        .video-subtext {
          text-align: center;
          padding-top: 2.125rem;

          font-family: "VCR OSD Mono";
          text-transform: uppercase;

          white-space: nowrap;
        }
      }

      .text-container {
        display: flex;
        flex-direction: row;
        .bird {
          display: none;
        }
      }

      .bird {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        z-index: 5;
        min-width: 25rem;
        max-width: 25rem;

        .bird-img {
          height: auto;
          object-fit: cover;
        }

        &.left {
          transform: translateX(35%);
          .bubble {
            width: 60%;
            margin-left: -4rem;
            margin-bottom: -2%;
          }

          &.extra-small {
            display: none;
          }
        }
        &.right {
          transform: translateX(-38%);
          .bubble {
            width: 60%;
            align-self: end;
            margin-right: -5rem;
            margin-bottom: -2%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .section-video {
    .content {
      .youtube {
        iframe,
        .video-placeholder {
          width: 600px;
          height: 400px;
        }

        .bird {
          min-width: 300px;

          &.left {
            .bubble {
              margin-left: -4rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .section-video {
    .content {
      .youtube {
        .hidden-sm {
          display: none;
        }

        iframe,
        object,
        embed,
        .video-placeholder {
          width: 600px;
          height: 400px;
          min-width: 600px;
          min-height: 400px;
        }

        .text-container {
          display: flex;
          flex-direction: row;
          .video-subtext {
            margin-top: 4rem;
            padding: 0 2rem;
          }
          .bird {
            display: flex;
          }
        }

        .bird {
          .bird-img {
            width: 200px;
            min-width: 200px;
          }
          &.left {
            transform: translate(68%, -3%);
            .bubble {
              width: 45%;
              transform: translate(2rem, 1rem);
            }
          }
          &.right {
            transform: translate(-32%, -3%);
            .bubble {
              width: 45%;
              align-self: end;
              transform: translate(-9rem, 1rem);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .section-video {
    .content {
      .youtube {
        iframe,
        object,
        embed,
        .video-placeholder {
          width: 310px;
          height: auto;
          min-width: 310px;
          min-height: 230px;
        }

        .text-container {
          .bird {
            &.left {
              transform: translate(105%, 31%);

              .bubble {
                width: 38%;
                transform: translate(30%, 1.5rem);
              }
            }
          }

          .bird {
            &.right {
              transform: translate(-72%, 31%);

              .bubble {
                width: 38%;
                align-self: normal;
                transform: translate(110%, 1.5rem);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .section-video {
    .content {

      .title-content-medium {
        font-size: 2.4rem;
      }
      .youtube {

        iframe,
        object,
        embed,
        .video-placeholder {
          width: 240px;
          height: auto;
          min-width: 240px;
          min-height: 180px;
        }
        
        .text-container {

          .video-subtext {
            font-size: 0.9rem;
          }
          .bird {
            .bird-img {
               width: 100px;
               min-width: 150px;
            }

            &.left {
              transform: translate(110%, 60%);

              .bubble {
                width: 30%;
                transform: translate(65%, -1%);
              }
            }
            &.right {
              transform: translate(-58%, 60%);

              .bubble {
                width: 32%;
                align-self: normal;
                transform: translate(62%, -1%);
              }
            }
          }
        }
      }
    }
  }
}
