@import "../../../styles/variables";

.section-top {
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;

  .container {
    width: 100%;
    padding: 0;
  }

  .bin-kingz-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    &.small {
      display: none;
    }

    .left .right {
      padding-bottom: 3.125rem;
      width: 25%;

      img {
        max-width: 100%;
      }
    }

    .title-header {
      margin-bottom: 3.125rem;

      div {
        letter-spacing: 2px;
        color: black;
        font-family: "GTPRessura Bold";
      }
      .first {
        font-size: 2.8rem;
      }
      .second {
        font-size: 6.5rem;
        margin-top: -1.625rem;
      }
    }

    .subtitle-header {
      font-family: "VCR OSD Mono";
      white-space: nowrap;
      color: black;
    }

    .play-video-text {
      color: black;
      font-size: 1.3rem;
      font-family: "VCR OSD Mono";
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-top: 7rem;

      span {
        font-family: "VCR OSD Mono";
        background-color: black;
        padding: 0.5rem;
      }
    }

    .left {
      width: 40%;
      max-width: 56rem;
      margin-top: 8.125rem;
      transform: translateX(-32%);
      position: relative;

      .left-bubble {
        position: absolute;
        width: 45%;
        right: 0;
        margin-right: -5rem;
        margin-top: -8.5rem;
        z-index: 4;
      }

      .trash {
        position: relative;
        z-index: 2;
        left: 3.025rem;
        width: 70%;
      }

      .bird {
        position: absolute;
        z-index: 4;
        width: 60%;
        left: 36%;
        bottom: 2rem;
      }

      .bin-small {
        position: absolute;
        z-index: 3;
        width: 25%;
        bottom: 3.5rem;
        left: 59%;
      }

      .bird-small {
        position: absolute;
        z-index: 3;
        width: 25%;
        bottom: -3.6rem;
        left: 55.25%;
      }
      .puddle {
        position: absolute;
        left: -4.0625rem;
        bottom: -3rem;
        transform: scale(1.2);
        z-index: 1;
      }
    }

    .right {
      width: 40%;
      max-width: 56rem;
      transform: translate(18%, 48%);
      position: relative;

      .right-bubble {
        position: absolute;
        width: 25%;
        left: 0;
        margin-left: 5%;
        margin-top: -22%;
        z-index: 5;
        animation-delay: 1s;
      }
      .trash {
        position: relative;
        z-index: 2;
        width: 70%;
        left: 22%;
        bottom: 2rem;
      }
      .bird {
        position: absolute;
        z-index: 4;
        width: 60%;
        right: 20%;
        bottom: 12%;
      }

      .bin-small {
        position: absolute;
        z-index: 1;
        right: 10%;
        bottom: 50%;
        width: 30%;
      }

      .bird-small {
        position: absolute;
        z-index: 4;
        right: 70%;
        bottom: 7%;
        width: 28%;
      }

      .puddle {
        position: absolute;
        right: -1rem;
        bottom: -3rem;
        z-index: 1;
        width: 90%;
      }
    }

    .center {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      .title-header {
        img {
          transform: scale(1.5);
          min-width: 300px;
        }
      }
    }
  }

  .bird-container-small {
    display: none;
  }


  @media screen and (max-width: 1280px) {
    .bin-kingz-content {
      .center {
        .title-header {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    .bin-kingz-content {
      .center {
        .title-header {
          img {
            transform: scale(1.2);
          }
        }
      }

      .subtitle-header {
        min-width: 300px;
        white-space: normal;
      }

      .play-video-text {
        margin-top: 2rem;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .bin-kingz-content {
      .left {
        transform: translate(-29%, 70%);
        .left-bubble {
          margin-top: -4rem;
        }
        .puddle {
          bottom: -1.5rem;
          left: -1rem;
        }
        .bird {
          left: 42%;
          bottom: 1rem;
        }
        .bin-small {
          bottom: 2rem;
          left: 65%;
        }
        .bird-small {
          left: 61%;
          bottom: -1.25rem;
        }
      }
      .right {
        transform: translate(13%, 150%);
        .puddle {
          bottom: -1rem;
          right: 1rem;
        }
      }
      .center {
        transform: translateY(-70%);
        .subtitle-header {
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    // background: $yellow;

    &.full-page {
      min-height: 60vh;
    }

    .bin-kingz-content {
      .center {
        width: 80%;
        transform: translateY(0);

        .title-header {
          img {
            transform: scale(1);
          }
          margin-bottom: 2rem;
        }
        .subtitle-header {
          margin-bottom: 0;
        }
      }
    }

    .bird-container-small {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: 22rem;

      margin-top: 2rem;

      .left-bird, .right-bird {
        color: black;
        width: 100%;
        height: 100%;
        position: relative;
        transform: scale(1.2);

        .bird-big {
          position: absolute;
        }
      }

      .left-bird {
        left: -18%;
        margin-top: 14%;
      }
      .right-bird {
        right: -10%;
        .bird-big {
          width: 90%;
        }
      }

      .bubble-small {
        width: 45%;
        position: absolute;

        &.left {
          left: 65%;
          margin-top: -12%;
          transform: rotate(-5deg);
        }

        &.right {
          width: 30%;
          margin-left: 13%;
          margin-top: 3%;
          transform: rotate(5deg);
        }

      }
    }

  }

  @media screen and (max-width: 640px) {
    .bin-kingz-content {
      margin-top: 6rem;
      margin-bottom: 3rem;
      .play-video-text {
        display: flex;
        flex-flow: column;
      }
    }
  }

  @media screen and (max-width: 410px) {
    .bin-kingz-content {
      .subtitle-header, .play-video-text {
        font-size: 0.9rem;
      }

      .subtitle-header {
        min-width: initial;
      }
      .play-video-text {
        margin: 2rem 0;
        span {
          font-size: 0.9rem;
        }
      }

      .center {
        .title-header {
          img {
            width: 100%;
            transform: none;
            min-width: 240px;
          }
        }
      }
    }

    .bird-container-small {
      height: 14rem;
    }
  }

  // min-width for large screen
  @media screen and (min-width: 1920px) {
    background-size: 100% 61%;

    .content {
      .bin-kingz-content {
        padding-top: 0;
        margin-top: 0;
      }

      .footer-btn {
        position: absolute;
        bottom: 3.125rem;
        width: 80%;
        max-width: 1920px;
      }
    }
  }
}
