@import '../../../styles/variables';

.section-mint {
  // padding-bottom: 4rem;
  position: relative;

  .gallery {
    position: absolute;
    width: 110%;
    height: 100%;
    top: -60%;
    left: 6%;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: row;

    justify-content: space-between;

    z-index: 0;

    .gallery-col {
      display: flex;
      flex-flow: column;
      justify-content: center;

      width: 100%;
      overflow: hidden;

      &.first {
        margin-top: -30%;
      }
      &.second {
        margin-top: 20%;
      }

      &.third {
        margin-top: -20%;
      }

      img {
        width: 20%;
        max-width: 18rem;
        position: absolute;
        padding: 15rem 0;
      }
    }
  }

  .content {
    display: flex;
    min-height: 100vh;
    padding-bottom: 2rem;
    flex-direction: column;
    overflow: hidden;

    .center {
      margin: auto;

      #mint-modal {
        position: absolute;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }
      #about-modal {
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }

      .center-modal {
        padding: 1rem 2rem;
        border: 1px solid $white;
        border-radius: 1rem;

        width: 400px;
        height: 550px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;

        background-color: $blue;
        position: relative;
        z-index: 10;

        .modal-header {
          font-family: 'VCR OSD Mono';
        }
        .modal-text {
          font-family: 'GTPressura Bold';
          font-size: 3.5rem;
          white-space: pre-line;
          line-height: 90%;
        }
        .modal-description {
          margin: auto;
          width: 80%;
        }

        .account-info {
          display: flex;
          flex-flow: column;
          justify-content: center;
          .account-row {
            font-weight: bold;
            &.claim {
              margin-top: 1rem;
              p {
                font-weight: bold;
                a {
                  font-weight: normal;
                  text-decoration: underline;
                  cursor: pointer;

                  &:hover {
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }

        .mint-section-button {
          background-color: $black;
          border-radius: 0.5rem;
          width: 200px;
          margin: 0 auto;
          font-family: 'VCR OSD Mono';
          text-transform: uppercase;
          letter-spacing: 0.75px;
          text-align: center;

          padding: 1rem 0.5rem;
        }

        .sales-inactive,
        .sold-out,
        .max-reached {
          cursor: not-allowed;
          opacity: 0.5;
        }

        .amount-input {
          display: flex;
          flex-flow: row;
          justify-content: center;
          width: 50%;
          margin: 0 auto;
          border-radius: 0.5rem;

          .claim-amount {
            margin: 0 0.5rem;
            background-color: black;
            border-radius: 0.5rem;
            color: white;
            width: 50px;
          }
          i {
            margin: auto 0;
            padding: 0.25rem 0.5rem;
            background-color: black;
            color: white;
            border-radius: 0.5rem;
            cursor: pointer;

            &:hover {
              opacity: 0.6;
            }

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }

        .mint-button {
          cursor: pointer;
          img {
            width: 150px;
          }
          &:hover {
            filter: opacity(0.8);
          }

          &.processing {
            filter: opacity(0.5) brightness(0.8);
            cursor: not-allowed;
          }
        }

        .connect-wallet {
          &:hover {
            opacity: 0.8;
          }
          &.processing {
            opacity: 0.5;
            cursor: not-allowed;
          }

        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .title-content-medium {
      font-size: 2.6rem;
    }

    .content {
      .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .center-modal {
          width: 90%;
          height: auto;
          gap: 1.25rem;
          margin: 0 auto;

          .mint-button {
            img {
              width: 125px;
            }
          }
        }
      }
    }
  }
}
