@import '../../styles/variables';

.toast-container-bk {
  * {
    color: $light-black;
  }
  a {
    color: $blue;

    &:hover {
      opacity: 0.8;
    }
  }
}
