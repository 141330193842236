@import "../../../styles/variables";

.section-video-takeover {
  position: relative;
  z-index: 8;

  .content {
    height: 100vh;
    width: 100%;
    // padding: 5rem 0;
  }

  .container {
    width: 100%;
    padding: 0 !important;
  }

  .takeover {
    width: 100%;
    position: relative;

    .bird {
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: relative;
      
      margin: 0 auto;

      img {
        margin: 0 auto;
        width: 30%;

      }
    }

    .tv-container {
      display: flex;
      justify-content: center;

      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;

      .button-container {
        position: relative;
        bottom: -1.5%;

        margin: auto;
      }

      .video-popup-button {
        position: relative;
        display: inline-block;

        z-index: 3;
        bottom: 10%;

        font-family: "VCR OSD Mono";
        text-align: center;
        font-size: 2.4rem;
        background-color: black;

        padding: 0.5rem;

        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        cursor: none;

        &.active {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
    
  }

  .video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 100;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .overlay-background {
      position: absolute;
      width: 100%;
      min-height: 100vh;
      background: rgba(0, 0, 0, 0.9);
    }

    .video {
      position: absolute;
      width: 65%;
      top: 56%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .video-container {
      width: 100%;
      margin: auto;
      transform: translateY(45%);
    }


    .btn-close {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 19%;
      right: 0;
      z-index: 101;
    }
  }

  @media screen and (max-width: 1300px) {
    .takeover {
      .tv-container {
        .video-popup-button {
          font-size: 1.8rem;
        }
      }
    }
  }
  

  @media screen and (max-width: 992px) {
    .video-popup {
      .video {
        width: 80%;
      }
    }

    .takeover {
      margin-top: 22%;
      .tv-container {
        .video-popup-button {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .takeover {
      margin-top: 30%;
      .tv-container {
        .video-popup-button {
          font-size: 1rem;
          padding: 0.25rem;
        }
      }
    }

    .video-popup {
      .btn-close {
        top: 16.5%;
      }
    }
  }

  @media screen and (max-width: 568px) {
    .takeover {
      margin-top: 38%;
      .tv-container {
        .button-container {
          bottom: -0.75%;
        }
        .video-popup-button {
          font-size: 0.8rem;
        }
      }
    }
  }

  @media screen and (max-width: 481px) {
    .takeover {
      margin-top: 38%;
      .tv-container {
        .button-container {
          bottom: -0.5%;
        }
        .video-popup-button {
          font-size: 0.6rem;
        }
      }
    }
    .video-popup {
      .btn-close {
        top: 10.5%;
      }
    }
  }
}
