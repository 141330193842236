@import "../../../styles/variables";


.section-about {
  color: $white;
  overflow: hidden;
  position: relative;
  z-index: 8;

  .content {
    padding: 6.25rem 0;

    .title-content-medium {
      margin-top: 3.75rem;
      margin-bottom: 1.25rem;
      font-family: "GTPressura Bold";
    }

    .box-content {
      display: flex;
      align-items: flex-start;
      .left {
        width: 45%;
        padding-right: 3.125rem;
      }

      .right {
        width: 55%;

        .vhs-filter {
        }

        .title-about-scott {
          padding-left: 2.5rem;
          margin-bottom: 1.25rem;
          font-family: "VCR OSD Mono";
          font-size: 1.75rem;
        }
        p {
          color: $dark-grey;
          position: relative;
          padding-left: 2.5rem;
        }

        p.content_scott {
          a {
            color: $dark-grey;
            font-size: 1.375rem;
            text-decoration: underline;
          }
          a:hover {
            text-decoration: none;
          }
        }

        .follow-scott-banner {
          margin-top: 4rem;
          margin-bottom: 2rem;
          margin-left: 11rem;

          .follow-scott-text {
            font-family: "VCR OSD Mono";
            letter-spacing: 0rem;
          }
          span {
            padding-right: 2rem;
          }
        }
      }

      p {
        color: $dark-grey;
        font-size: 1.375rem;
        line-height: 1.625rem;
        margin-bottom: 1.875rem;
      }

      &.first {
        align-items: initial;

        .left {
          display: flex;
          flex-direction: column;
          gap: 1.875rem;
          flex: 1;

          p {
            margin-bottom: 0;
          }

          .img-scott-marsh {
            margin-top: 1.5rem;
            max-width: 250px;
          }
        }

        .right {
          text-align: center;
          flex: 1;
          .artist-flowers {
            max-width: 574px;
            margin-top: 3.75rem;

            @media screen and (max-width: 1300px) {
              max-width: 500px;
            }
          }
        }
      }

      &.second {
        padding: 3.75rem 0;
        display: flex;
        justify-content: center;
      }

      &.last {
        margin-top: 3.75rem;
        .left {
          margin: auto 0;
        }
        .right {
          position: relative;
          margin: auto;
        }
      }
    }

    // REMIDNER: fix phone compatability once new page is ok
    @media screen and (max-width: 1300px) {
      .artist-flowers {
        max-width: 400px;
      }
    }

    @media screen and (max-width: 1120px) {
      .title-content-medium {
        text-align: center;
      }

      .box-content {
        flex-direction: column;
        gap: 3.125rem;

        .left,
        .right {
          width: 100%;
          padding: 0;
        }

        // banner
        .right {
          .follow-scott-banner {
            margin-left: 2.6rem;
          }
        }

        &.first {
          margin-top: 3.75rem;
          .left {
            text-align: center;
            img {
              margin: auto;
            }
          }
        }

        &.last {
          .left {
            display: flex;
            margin: auto;
            justify-content: center;
            img {
              margin: auto;
              max-width: 500px;
            }
          }
          .right {
            text-align: center;
            margin-top: 0;
            .title-about-scott {
              padding-left: 0;
            }
            p {
              padding-left: 0;
            }
          }
        }
      }
      .box-content {
        &.second {
          margin-top: 0;

          .left {
            text-align: center;
          }

          .right {
            text-align: center;
            padding-bottom: 0;

            .img-scott-portrait {
              position: initial;
              padding: 0;

              img {
                position: initial;
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 0 7rem 0;

      img {
        max-width: 100% !important;
      }

      .box-content {
        .right {
          .follow-scott-banner {
            margin-left: 0;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .box-content {
        .right {
          .follow-scott-banner {
            span {
              padding-right: 1rem;
            }
          }
        }
      }
    }

    // large width screen
    @media screen and (min-width: 1600px) {
      .box-content {
        &.second {
          .right {
            .img-scott-portrait {
              img {
                width: 100%;
              }
            }
          }
        }

        &.first {
          .left {
            p {
              max-width: 88%;
            }
          }
        }
      }
    }
  }
}

.section-about::after {
  background-image: url("/assets/grainy-white.png");
  background-size: cover;
  background-position: center;
}


.svg-filter {
  position: absolute;
  bottom: 0;
}

.vhs-filter {
  display: inline-block;
  overflow: hidden;
  position: relative;

  img {
    display: block;
  }

  &.__r,
  &.__g {
    left: 0;
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    transition: transform .4s;
    z-index: 1;
  }

  &.__r {
    filter: url('#r');
  }

  &.__g {
    filter: url('#g');
  }
  &.__b {
    filter: url('#b');
  }

  &:hover {
    .__r {
      transform: translate(0.25rem, 0.25rem);
    }
  
    .__g {
      transform: translate(-0.15rem, 0.063rem);
    }
  }
}