// color variables
$white: #FFFFFF;
$black: #000000;
$light-black: #1C1C1C;
$blue: #3177FF;
$yellow: #FCC800;
$yellow-orange: #FFAF13;
$red: #FF0000;
$orange-red: #FF3939;
$orange: #FF8903;
$green: #007919;
$dark-grey: #8B8B8B

