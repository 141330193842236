@import '../../../styles/variables';

.section-bin-juice {
  background: url('/assets/images/beer-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  object-fit: cover;
  position: relative;
  margin: auto 0;
  z-index: 10;

  .container {
    display: flex;
    justify-content: center;
  }

  .bin-juice-container {
    margin: auto 0;
  }

  .font-main {
    font-size: 1.625rem;
  }

  .font-large {
    font-size: 1.5rem;
  }

  .header-logo {
    margin: 0 auto;
    width: 55%;
    margin-top: 5%;
    display: flex;
    align-content: center;
    max-width: 1200px;
    img {
      margin: 0 auto;
      width: 90%;
    }
  }

  .main-body {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-bottom: 2rem;
    min-height: 600px;
  }
  .small-part {
    display: none;
  }
  .part {
    &.left,
    &.right {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      width: 18%;
      max-height: 900px;
    }
    &.left {
      .bird {
        object-fit: contain;
        transform: scale(2.4) translateY(-25%);
        max-height: 820px;
      }
    }

    &.center {
      width: 50%;
      display: flex;
      flex-flow: column;
      align-content: center;
      max-width: 800px;
      margin: 0 2rem;
      .text-header {
        font-family: 'VCR OSD Mono';
        text-transform: uppercase;
        color: black;
        letter-spacing: 0.5px;
        text-align: center;
        margin-top: 1rem;
      }

      .text-description {
        margin: 2rem auto;
        color: black;
        text-align: center;
        width: 400px;
      }

      .incentive-can {
        background-color: $yellow-orange;
        padding: 1.25rem 1rem;
        border-radius: 10px;
        width: 80%;
        max-height: 480px;
        margin: 0 auto;

        p,
        div {
          color: black;
          text-align: center;
        }
        .incentive-top {
          font-family: 'VCR OSD Mono';
          text-transform: uppercase;
          margin-bottom: 1rem;
          position: relative;

          .beer {
            position: absolute;
            width: 180px;
            left: -20%;
            top: -90%;
          }

          .text {
            width: 60%;
            text-align: center;
            margin: 0 auto;
            font-family: 'VCR OSD Mono';
          }

          .bird {
            position: absolute;
            width: 130px;
            right: -15%;
            top: -60%;
          }
        }

        .incentive-info {
        }
      }
    }

    &.right {
      .beer-cans {
        position: relative;
        transform: translateY(-18%);
        .beer {
          max-height: 550px;
          transform: scale(1.6) translateY(-12%) translateX(10%);
          margin: 0 auto;
        }
      }
    }

    .cta {
      font-family: 'VCR OSD Mono';
      text-transform: uppercase;
      background-color: $orange-red;
      text-align: center;

      width: 100%;
      min-width: 200px;
      max-width: 320px;
      margin: 0 auto;
      border-radius: 10px;
      padding: 0.5rem 1rem;

      font-size: 1.25rem;

      position: absolute;
      left: 0;
      right: 0;
      bottom: 2%;

      cursor: pointer;

      &:hover {
        filter: brightness(.8);
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .font-large {
  }
  .section-bin-juice {
    .part {
      &.center {
        width: 65%;

        .incentive-can {
          .incentive-top {
            .beer {
              width: 120px;
              top: -60%;
              left: -16%;
            }
            .bird {
              width: 110px;
              top: -50%;
            }
          }
        }
      }

      &.right {
        .beer-cans {
          .cta {
            min-width: 200px;
            bottom: -10%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .section-bin-juice {
    .header-logo {
      width: 75%;
    }
    .small-part {
      display: flex;
      flex-flow: row;
      position: relative;
      justify-content: space-evenly;
      width: 100%;

      .beer-cans {
        position: relative;
        display: flex;
        flex-flow: flex-end;
        width: 200px;

        .beer {
          object-fit: contain;
        }
      }

      .cta {
        bottom: -4%;
        min-width: initial;
        max-width: none;
      }
      .bird {
        width: 280px;
      }
    }

    .part {
      &.left,
      &.right {
        display: none;
      }
      &.center {
        width: 80%;
        .text-header {
          br {
            display: none;
          }
        }
        .text-description {
          width: 100%;
        }
        .incentive-can {
          .incentive-top {
            .beer {
              top: -60%;
              left: -18%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .section-bin-juice {
    .header-logo {
      width: 100%;
    }
    .main-body {
      width: 100% !important;
    }
    .small-part {
      flex-flow: column-reverse;
      margin: 1.25rem auto;
      align-content: center;
      justify-content: center;

      .beer-cans {
        margin: 0 auto;

        .beer {
          object-fit: contain;
        }
      }

      .bird {
        margin: 0 auto;
      }
    }

    .part {
      &.center {
        width: 100%;
        .incentive-can {
          width: 100%;
          .incentive-top {
            .beer {
              top: -55%;
              left: -15%;
            }
            .bird {
              right: -10%;
              top: -45%;
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 480px) {
  .section-bin-juice {
    .part {
      &.center {
        width: 100%;
        .incentive-can {
          width: 100%;
          .incentive-top {
            .beer {
              top: -25%;
              left: -35%;
              width: 100px;
            }
            .bird {
              width: 80px;
              right: -30%;
              top: -18%;
            }
          }
        }
      }
    }
  }
}

