@import "../../../styles/variables";

.section-roadmap-mobile {
  padding: 8rem 0;
  display: none;

  position: relative;
  z-index: 8;
  
  .container {
    width: 100%;
  }

  .title-content-medium {
    padding-top: 5.312rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .header-content {
    font-family: "VCR OSD Mono";
    text-transform: uppercase;
    color: black;
    margin-bottom: 2rem;
  }

  .description-content {
    color: black;
  }

  .roadmap {
    padding-top: 5rem;
  }

  .roadmap-goal-modal {
    background: black;

    .goal-title {
      text-align: center;
      padding: 0.937rem;
      color: black;
      background: $yellow-orange;
      border: 2.4px dashed black;
      border-top-left-radius: 1.25rem;
      border-top-right-radius: 1.25rem;
    }

    .goal-description {
      background: black;
      padding: 0.937rem;
      border: 2.4px dashed black;
      text-align: center;
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
    }
  }

  .bk-roadmap-parent {
    .bk-roadmap-child {
      position: relative;
      border: 2.4px dashed black;
      overflow: hidden;

      .box {
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;

        &.box-1 {
          top: 39%;
          left: 30%;
        }
        &.box-2 {
          left: 43%;
          bottom: 20%;
        }
        &.box-3 {
          right: 31%;
          bottom: 17%;
        }
        &.box-4 {
          right: 21%;
          bottom: 39%;
        }
        &.box-5 {
          top: 36%;
          right: 16%;
        }
      }
    }

    .roadmap-img {
      transition: all 0.3s ease-in-out;
    }
  }

  .title-content {
    position: relative;
    z-index: 4;
    text-align: center;
    img {
      width: 55%;
      margin-bottom: 2rem;
    }
  }

  .dotted-box {
    font-family: "VCR OSD Mono";
    text-transform: uppercase;
    background-color: #ffaf13;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 3rem;
    border-radius: 1.25rem;
  }
  @media screen and (max-width: 864px) {
    display: block;
    padding-bottom: 20rem;

    // .container {
    //   .roadmap-trigger {
    //     padding-top: 18%;
    //   }
    // }
  }

  @media screen and (max-width: 640px) {
    .dotted-box {
      width: 100%;
      margin-bottom: 2rem;
    }

    .roadmap-goal-modal {
      .goal-title,
      .goal-description {
        font-size: 1rem;
        padding: 0.612rem;
      }
    }
  }
}
