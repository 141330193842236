@import "../../../styles/variables";

.section-faq {
  color: $white;
  height: 100%;
  position: relative;
  z-index: 8;

  .container {
    width: 100%;
  }

  .content {
    display: flex;
    flex-flow: row wrap;
    padding: 7rem 0;

    .title-content-medium {
      &.small {
        display: none;
      }
    }

    .content-left {
      width: 45%;
      margin-top: 4.25rem;
      position: relative;
      transform: translateX(-10%);

      .oil-container-small {
        display: none;
      }

      .oil-container {
        // position: absolute;
        margin-left: -10rem;
        margin-top: 15rem;

        .bird {
          position: absolute;
          z-index: 2;
          left: -4rem;
          top: -6rem;
          img {
            width: 70%;
            max-width: 60rem;
          }

          .bubble {
            position: absolute;
            right: 0;
            width: 50%;
            transform: rotate(-5deg);
            margin-top: -2rem;
            margin-right: 4rem;
            img {
              width: 60%;
            }
          }
        }
      }

      .car {
        position: absolute;
        top: 0;
        left: -16rem;
        margin-top: 7rem;
        z-index: 3;
        img {
          width: 80%;
        }
      }
    }
    .content-right {
      width: 55%;
      margin-top: 2rem;
      z-index: 5;
      padding-right: 10%;

      .faq-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 3rem;

        .faq-item {
          width: 25rem;
          min-width: 18rem;
          margin-top: 2rem;

          .faq-question {
            background-color: rgba(0, 0, 0, 0.2);

            font-family: "VCR OSD Mono";
            text-transform: uppercase;
            padding: 1.25rem;
            cursor: pointer;
          }

          .faq-answer {
            margin-top: 1rem;
            white-space: pre-line;
            transition: all 0.5s ease-in-out;
            max-height: 0;
            overflow: hidden;

            &.active {
              transition: all 0.5s ease-in-out;
              max-height: 500px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .content {
      .content-right {
        padding-left: 12rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .content {
      .title-content-medium {
        &.regular {
          display: none;
        }
        &.small {
          display: initial;
          padding-left: 10%;
        }
      }
      .content-left {
        width: 100%;
        transform: none;

        margin-top: 16rem;

        .oil-container {
          margin-top: 10rem;

          .bird {
            left: -7%;
            top: -8rem;

            .bubble {
              margin-right: 10rem;
              img {
                width: 60%;
              }
            }

            .bird-img {
              max-width: 55%;
              min-width: 55%;
            }
          }

          .car {
            margin-top: 2rem;
          }
        }
      }
      .content-right {
        padding-left: 0;
        padding-right: 0;
        padding: 0 10%;
        width: 100%;
      }
    }
  }

  // bird behaves weirdly at smaller sizes
  @media screen and (max-width: 860px) {
    .content {
      .content-left {
        margin-top: 4rem;
        svg {
          width: 100%;
          margin-top: -40%;
        }
        .oil-container-small {
          display: initial;
          margin-left: -14%;
        }
        .oil-container {
          display: none;
        }
      }
      .content-right {
        .faq-content {
          .faq-item {
            width: 16rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 745px) {
    .content {
      .content-right {
        .faq-content {
          justify-content: center;
          .faq-item {
            width: 28rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    padding: 0;

    .content {
      .title-content-medium {
        text-align: center;
        margin: 0 auto;

        &.small {
          padding: 0;
        }
      }
    }
  }

  @media screen and (max-width: 384px) {
    .title-content-medium {
      font-size: 3rem;
    }
    .content {
      .content-right {
        .faq-content {
          .faq-item {
            min-width: 10rem;
            width: 100%;
          }
        }
      }
    }
  }
}
