@import "../../styles//variables";

.footer {
  color: $black;
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(3.75rem);
  visibility: hidden;
  overflow: hidden;

  border-top: 2px solid;

  &.hidden {
    visibility: hidden !important;
  }
  
  &.black {
    border-color: black;
  }
  &.white {
    border-color: white;
  }
  &.opaque {
    border: none;
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  .wrapper {
    &.opaque {
      background-color: #ffffff47;
      background-blend-mode: color-dodge;
    }
    .wrap-list {
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.6rem 0;
      z-index: 11;

      .scott-icon {
        margin-top: 0.3rem;
        svg {
          &.black {
            path {
              fill: black;
            }
          }
          &.white,
          &.opaque {
            path {
              fill: white;
            }
          }
        }
      }

      .icons-container {
        display: flex;
        align-items: center;
        li {
          text-align: center;
          white-space: nowrap;
          padding: 0 2rem;

          svg {
            &.black {
              path {
                fill: black;
              }
            }
            &.white,
            &.opaque {
              path {
                fill: white;
              }
            }
          }
        }
      }

      .fa-brands {
        &.black {
          color: black;
        }
        &.white,
        &.opaque {
          color: white;
        }
      }

      .center-content {
        width: 60%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          display: inline-block;
          margin: 10px 1.562rem 0 1.562rem;
          color: $black;
        }
        img,
        span {
          vertical-align: middle;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .wrapper {
      .wrap-list {
        .icons-container {
          li {
            padding: 0 1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    position: initial;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    background: $blue !important;
    border-top: none;
    .container {
      width: 100%;
      .wrap-list {
        padding: 1rem 0;
        .center-content {
          flex-direction: column;
          gap: 1.8rem;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .wrap-list {
      flex-wrap: wrap;
      justify-content: center !important;

      .scott-icon {
        order: 0;
      }

      .icons-container {
        &.right {
          width: 100%;
          justify-content: center;
          border-top: thin solid $white;
          margin-top: 1rem;
          padding-top: 1rem;
        }
      }
    }
  }
}
