@import "../../styles/variables";

.header {
  position: fixed;
  width: 100%;
  height: 6.875rem;
  z-index: 12;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(-7.5rem);
  visibility: hidden;

  &.hidden {
    display: none !important;
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  .header-top {
    .logo {
      background-color: $black;
      padding: 1.45rem 2.5rem;
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.3s all;

      &.black {
        background-color: $black;

        path {
          fill: white;
        }
      }

      &.white,
      &.opaque {
        background-color: white;

        path {
          fill: black;
        }
      }
      .logo-svg {
        margin: 1.25rem 0;
      }
    }

    .mobile-trigger {
      display: none;
    }

    .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.right {
        margin-right: 2.4rem;
      }
      .menu-item {
        text-align: center;
        padding: 0 1rem;
        white-space: nowrap;
        width: 140px;

        &.logo {
          width: auto;
          margin-left: 0.5rem;
        }
      }

      a,
      span {
        cursor: pointer;
        // color: $black;
        // padding: 0 1.562rem;
        text-align: center;
        display: inline-block;
        font-weight: 600;
      }

      .menu-item {
        a {
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
          transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.3s all;
          &.black {
            color: black;
          }

          &.white {
            color: white;
          }

          &.opaque {
            color: white;
          }
        }
      }

      .btn-connect-wallet {
        max-width: 330px;
        margin-left: 1.562rem;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .container {
      width: 100%;

      .header-top {
        .logo {
          .logo-svg {
            margin: 0.75rem 0;
            width: 125px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;

    .header-top {
      width: 100%;
      padding: 1rem 1.562rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $blue !important;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 500;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

      .logo-mobile {
        position: relative;
        z-index: 10;

        svg {
          width: 4.275rem;
          height: 2.5rem;
        }
      }

      .mobile-trigger {
        text-align: center;
        display: inline-block;
        transition: all 0.3s ease-in-out;
        position: relative;
        width: 2.1875rem;
        height: 2.1875rem;
        cursor: pointer;
        z-index: 100;
        img {
          max-width: 100%;
          filter: invert(1);
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;

          &.close-menu {
            width: 1.562rem;
            left: 0;
            top: 5px;
            opacity: 0;
          }
        }

        &.open {
          transform: rotateZ(270deg);
          .open-menu {
            opacity: 0;
          }
          .close-menu {
            opacity: 1;
          }
        }
      }

      .menu {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        text-align: center;
        z-index: 10;
        background-color: $blue;
        flex-direction: column;
        justify-content: center;
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        will-change: transition;

        .logo {
          display: none;
        }

        &.left,
        &.right {
          position: inherit;
          right: auto;
          left: auto;
          margin-right: 0;
          margin-left: 0;
        }

        .menu-item {
          margin-bottom: 2.5rem;

          &.hide {
            display: none;
          }
          a {
            margin-left: 0;
          }
        }

        &.show {
          transform: translateX(-1.562rem);
        }
      }
    }
  }

  @media screen and (max-width: 680px) {
    height: auto;
    .header-top {
      padding: 0.5rem 1.562rem;
      .menu {
        .btn-connect-wallet {
          margin-top: 0;
          margin-left: 0;
          max-width: 240px;
        }
      }
    }
  }
}
